/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import { VariableContent } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import OneTrustPrivacyPolicy from '../components/OneTrustPrivacyPolicy'

import footer from '../components/Footer'
import header from '../components/Header'

const PrivacyPolicy = ({ data }) => {
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Privacy Policy | Aetna Medicare Direct ',
          description:
            "Learn about Aetna's privacy policy for the Aetna Medicare Direct website.",
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/privacy-policy',
        promoCode: '123116',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: ' Privacy Policy | Aetna Medicare Direct ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <VariableContent
          backgroundColor="primary"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
          alignMainContent="center"
        >
          <OneTrustPrivacyPolicy />
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
