import styled from '@emotion/styled'
import { css } from '@emotion/core'

// Loading animations for the LoadingStep component
/* eslint-disable import/prefer-default-export */
export const OneTrustPrivacyPolicy = styled.section(
  () => css`
    body {
      background-color: #7d3f98 !important;
    }

    .leshen-variable-content {
      padding: 32px 16px !important;
    }

    .otnotice {
      width: 100%;

      @media screen and (min-width: 1200px) {
        width: 66.6666%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .otnotice-content {
      padding: 0 !important;
    }

    .otnotice-section {
      margin: 0 !important;
    }

    p {
      padding: 6px 16px !important;
      text-align: left;
      background-color: #7d3f98 !important;
      color: #ffffff !important;
      margin-bottom: 0 !important;
    }

    li {
      list-style-position: inside;
      text-align: left;
      background-color: #7d3f98 !important;
      color: #ffffff !important;
      margin-bottom: 0 !important;
      padding: 6px 16px;
    }

    ul {
      padding: 0 !important;
    }

    table {
      margin: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    td {
      background-color: #7d3f98 !important;
    }
  `
)
